"use client";

import { ReactNode } from "react";
import { PhoneAvatarProvider } from "./phone_betting/PhoneAvatar";
import { LoadingProvider } from "./LoadingProvider";
import { RoundResultProvider } from "./RoundResult";
import { PhoneBetProvider } from "./phone_betting/PhoneBet";
import MultibetProvider from "./multibet/MultibetProvider";
import { VolumeProvider } from "./VolumeProvider";
import { SystemAlertProvider } from "./SystemAlertProvider";
import { UserSettingsAlertProvider } from "./UserSettingsAlertProvider";
import { BalanceDisplayProvider } from "./BalanceDisplayProvider";
import { EventSourceProvider } from "./EventSourceContext";
import { ListBetsProvider } from "./ListBetsProvider";
import { SocketManagerProvider } from "./SocketManagerContext";
import { DomainProvider } from "./DomainContext";
export function Providers({ children }: { children: ReactNode }) {
  return (
    <EventSourceProvider>
      <DomainProvider>
        <SystemAlertProvider>
          <UserSettingsAlertProvider>
            <SocketManagerProvider>
              <BalanceDisplayProvider>
                <ListBetsProvider>
                  <PhoneBetProvider>
                    <PhoneAvatarProvider>
                      <LoadingProvider>
                        <RoundResultProvider>
                          <MultibetProvider>
                            <VolumeProvider>{children}</VolumeProvider>
                          </MultibetProvider>
                        </RoundResultProvider>
                      </LoadingProvider>
                    </PhoneAvatarProvider>
                  </PhoneBetProvider>
                </ListBetsProvider>
              </BalanceDisplayProvider>
            </SocketManagerProvider>
          </UserSettingsAlertProvider>
        </SystemAlertProvider>
      </DomainProvider>
    </EventSourceProvider>
  );
}
