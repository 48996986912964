// import { createContext, ReactNode, useContext, useState } from "react";

// export type RoundResult = {
//   table_id: string;
//   winner: number;
//   pcards: string;
//   bcards: string;
//   pscore: number;
//   bscore: number;
//   game_number: string;
// };
// interface IRoundResultContext {
//   roundResultMap: { [key: string]: RoundResult };
//   setRoundResult: (tableId: string, state: RoundResult) => void;
// }

// const RoundResultContext = createContext<IRoundResultContext | undefined>(
//   undefined,
// );

// const useRoundResultContext = () => {
//   const context = useContext(RoundResultContext);
//   if (!context) {
//     throw new Error(
//       "useRoundResultContext must be used within a RoundResultProvider",
//     );
//   }
//   return context;
// };

// const RoundResultProvider = ({ children }: { children: ReactNode }) => {
//   const [roundResultMap, setRoundResultMap] = useState<{
//     [key: string]: RoundResult;
//   }>({});

//   const setRoundResult = (tableId: string, state: RoundResult) => {
//     setRoundResultMap((prev) => ({ ...prev, [tableId]: state }));
//   };

//   //   const resetRoundResult = (tableId : string,  ) => {
//   // setRoundResultMap((prev) => ({ ...prev, [tableId]:  }));
//   //   }

//   return (
//     <RoundResultContext.Provider value={{ roundResultMap, setRoundResult }}>
//       {children}
//     </RoundResultContext.Provider>
//   );
// };

// export { useRoundResultContext, RoundResultProvider };

import { createContext, ReactNode, useContext, useState } from "react";

export type RoundResult = {
  table_id: string;
  winner: number | null; // Allow null
  pcards: string | undefined;
  bcards: string | undefined;
  pscore: number | null; // Allow null
  bscore: number | null; // Allow null
  game_number: string | null; // Allow null
};

interface IRoundResultContext {
  roundResultMap: { [key: string]: RoundResult };
  setRoundResult: (tableId: string, state: RoundResult) => void;
  resetRoundResult: (tableId: string) => void; // New method to reset the values
}

const RoundResultContext = createContext<IRoundResultContext | undefined>(
  undefined,
);

const useRoundResultContext = () => {
  const context = useContext(RoundResultContext);
  if (!context) {
    throw new Error(
      "useRoundResultContext must be used within a RoundResultProvider",
    );
  }
  return context;
};

const RoundResultProvider = ({ children }: { children: ReactNode }) => {
  const [roundResultMap, setRoundResultMap] = useState<{
    [key: string]: RoundResult;
  }>({});

  const setRoundResult = (tableId: string, state: RoundResult) => {
    setRoundResultMap((prev) => ({ ...prev, [tableId]: state }));
  };

  const resetRoundResult = (tableId: string) => {
    setRoundResultMap((prev) => ({
      ...prev,
      [tableId]: {
        ...prev[tableId],
        winner: null,
        pcards: "",
        bcards: "",
        pscore: null,
        bscore: null,
        game_number: null,
      },
    }));
  };

  return (
    <RoundResultContext.Provider
      value={{ roundResultMap, setRoundResult, resetRoundResult }}
    >
      {children}
    </RoundResultContext.Provider>
  );
};

export { useRoundResultContext, RoundResultProvider };
