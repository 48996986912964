import(/* webpackMode: "eager" */ "/codebuild/output/src4071864656/src/club-home-v1-fe/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Noto_Sans\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"notoSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/codebuild/output/src4071864656/src/club-home-v1-fe/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["CookiesProvider"] */ "/codebuild/output/src4071864656/src/club-home-v1-fe/src/common/context/CookieProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DeviceProvider"] */ "/codebuild/output/src4071864656/src/club-home-v1-fe/src/common/context/DeviceProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/codebuild/output/src4071864656/src/club-home-v1-fe/src/common/context/Providers.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4071864656/src/club-home-v1-fe/src/styles/globals.css");
